import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BreadcrumbLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
